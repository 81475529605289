
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { BAIconsMixin, PartnersRoutingMixin, ShowcaseEventMixin, VuetifyMixin } from '@/mixins';

import BestAthletesLogo from '../ui/BestAthletesLogo.vue';
import SideNavSectionTitle from './SideNavSectionTitle.vue';
import { TeamModel } from '../../models/team';
import OnboardingDialog from '@/views/onboarding/OnboardingDialog.vue';

@Component({
	components: {
		BestAthletesLogo,
		SideNavSectionTitle,
		OnboardingDialog,
	},
})
export default class PublicAthleteSideNav extends Mixins(VuetifyMixin, BAIconsMixin, PartnersRoutingMixin, ShowcaseEventMixin){
	@Prop({ default: true, type: Boolean }) value: boolean;

	mini = false;
	selectedMenu = 'PERFORMANCE';

	get PublicTeams(): Partial<TeamModel>[] {
		return [
			{
				id: '0001',
				name: 'Best Team',
				logoUrl: 'https://cdn.bestathletes.co/team-logos/daf599e1-ba24-4987-aee1-db20a999fb11.png',
			},
		]
	}

	loginRequired: boolean = false;
	gotoLoginRequired() {
		this.loginRequired = true;
	}
	gotoLogin() {
		this.$router.push('/');
	}

}
